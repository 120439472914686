import React, { ReactNode, useState } from 'react'
import { Collapse } from 'react-collapse'

interface AccordionProps {
	children: ReactNode
}

export const Accordion = ({ children }: AccordionProps) => {
	return <div className="border-t shadow">{children}</div>
}

interface AccordionItemProps {
	/**
	 * This is the text that will be shown when the accordion is collapsed
	 */
	title: string
	/**
	 * Pass this as true to the top accordion to apply a rounded top style
	 */
	top?: boolean
	/**
	 * Pass this as true to the bottom accordion to apply a rounded bottom style
	 */
	bottom?: boolean
	/**
	 * Pass this as true if you would like the item to be open by default
	 */
	openByDefault?: boolean
	titleTestId?: string
	children: ReactNode
}

export const AccordionItem = ({ title, top, bottom, openByDefault, children, titleTestId }: AccordionItemProps) => {
	const [open, setOpen] = useState(openByDefault || false)

	return (
		<div className={`border-b bg-white ${top && 'rounded-t'} ${bottom && 'rounded-b'}`}>
			<div className={`border-l-2 ${open ? 'border-ab-100' : 'border-transparent'}`}>
				<button
					onClick={() => setOpen(!open)}
					className={`flex w-full items-center justify-between py-5 px-4 hover:bg-slate-50 hover:text-slate-800 focus:outline-none sm:px-8 ${
						open ? 'bg-slate-50' : 'bg-white'
					}`}
				>
					<span data-cy={titleTestId} className="text-xl font-semibold">
						{title}
					</span>
					<div className="ml-4 h-full">
						<div className="flex h-6 w-6 items-center justify-center rounded-full bg-white shadow hover:bg-slate-50">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className={`h-3 w-3 ${open ? 'rotate-0' : 'rotate-180'}`}
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
								<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
							</svg>
						</div>
					</div>
				</button>
				<Collapse
					isOpened={open}
					theme={{
						collapse: 'ReactCollapse--collapse transition-all duration-200 ease-in-out',
						content: 'ReactCollapse--content'
					}}
				>
					<div className="overflow-hidden px-2 py-1 sm:px-8 sm:py-3">{children}</div>
				</Collapse>
			</div>
		</div>
	)
}
