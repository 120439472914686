import axios from 'axios'
import { ValidationError } from 'components/global/FormInputs'
import { Col, Row } from 'components/grid'
import React, { useState } from 'react'
import { SalesTeam } from '@arckit/types'
import RepCard from './RepCard'
import { Button } from '@arckit/ui'
import Title from 'components/typography/Title'
import { P } from 'components/typography/Paragraph'

const RepFinder = () => {
	const [zipCode, setZipCode] = useState('')
	const [salesTeam, setSalesTeam] = useState<SalesTeam | null>(null)
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const getSalesTeam = async (event: React.FormEvent) => {
		event.preventDefault()

		try {
			if (zipCode && zipCode.length === 5) {
				setLoading(true)
				setErrorMessage('')
				const response = await axios.get<SalesTeam>(`https://quotes.americanreading.com/api/v1/salesforce/sales-team/zip-code/${zipCode}`)
				const salesTeam = response.data
				setSalesTeam(salesTeam)
				setLoading(false)
			} else {
				setErrorMessage('Please enter a 5 digit zip code')
			}
		} catch (error: any) {
			setLoading(false)
			if (error.response.data.message === 'Could not locate territory') setErrorMessage(`"${zipCode}" does not appear to be a valid zip code. Please try again.`)
			else if (error.response.status === 404) setErrorMessage('We could not locate your ARC representatives, please try again.')
		}
	}

	return (
		<Row className="items-center justify-center">
			<Col width="w-full xl:w-1/3">
				<Title>Search by Zip Code</Title>
				<P size="md" className="mb-3">
					Enter your five digit zip code to locate your American Reading Company representatives.
				</P>
				<form onSubmit={getSalesTeam}>
					<div className="flex items-center">
						<input
							id="zipCodeSearch"
							name="zipCodeSearch"
							type="text"
							autoComplete="off"
							placeholder="Zip code..."
							value={zipCode}
							className="h-[54px] w-32 rounded-l-sm border-gray-300 px-4 placeholder:text-gray-400 hover:shadow-inner focus:border-ab-100 focus:shadow-inner focus:outline-none focus:ring-0"
							onChange={(event) => setZipCode(event.target.value)}
						/>
						<Button size="lg" rounded={false} loading={loading}>
							Locate Your Reps
						</Button>
					</div>
					{errorMessage && <ValidationError>{errorMessage}</ValidationError>}
				</form>
			</Col>
			<Col width="w-full xl:w-2/3" className={`mt-6 ${!salesTeam ? 'hidden' : 'block'} xl:mt-0 xl:pr-10`}>
				{salesTeam && (
					<Row>
						<RepCard data={salesTeam.accountManager} />
						<RepCard data={salesTeam.salesOperation} />
					</Row>
				)}
			</Col>
		</Row>
	)
}

export default RepFinder
