import MailingAddress from 'components/forms/MailingAddress'
import { Col, Row } from 'components/grid'
import React from 'react'

const ContactInfo = () => {
	return (
		<Row>
			<Col width="w-full md:w-1/3" className="mb-6">
				<div className="h-full rounded border bg-slate-100 shadow">
					<div className="flex flex-col p-6 text-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="mx-auto mb-3 h-20 w-20 text-gray-300"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth={2}
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
							/>
						</svg>
						<p className="text-2xl font-semibold text-ab-100">Call</p>
						<p className="mb-0 text-lg">
							<a href="tel:866-810-2665" className="text-slate-800">
								(866) 810-2665
							</a>
						</p>
						<p className="text-lg">
							<a href="tel:610-992-4150" className="text-slate-800">
								(610) 992-4150
							</a>
						</p>
					</div>
				</div>
			</Col>
			<Col width="w-full md:w-1/3" className="mb-6">
				<div className="h-full rounded border bg-slate-100 shadow">
					<div className="flex flex-col p-6 text-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="mx-auto mb-3 h-20 w-20 text-gray-300"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth={2}
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
							/>
						</svg>
						<p className="text-2xl font-semibold text-ab-100">Email</p>
						<p className="truncate text-lg">
							<a href="mailto:customercare@americanreading.com" className="text-slate-800">
								customercare@americanreading.com
							</a>
						</p>
					</div>
				</div>
			</Col>
			<Col width="w-full md:w-1/3" className="mb-6">
				<div className="h-full rounded border bg-slate-100 shadow">
					<div className="flex flex-col p-6 text-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="mx-auto mb-3 h-20 w-20 text-gray-300"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth={2}
						>
							<path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
							<path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
						</svg>
						<p className="text-2xl font-semibold text-ab-100">Mail</p>
						<p className="text-lg">
							<a
								href="https://www.google.com/maps/place/American+Reading+Company/@40.0804331,-75.3827734,17z/data=!3m1!4b1!4m5!3m4!1s0x89c695b109489a97:0xc2fa60d28138e3b3!8m2!3d40.080429!4d-75.3805847"
								target="_blank"
								rel="noopener noreferrer"
								className="text-slate-800"
							>
								<MailingAddress />
							</a>
						</p>
					</div>
				</div>
			</Col>
		</Row>
	)
}

export default ContactInfo
