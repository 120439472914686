import axios from 'axios'
import ContactInfo from 'components/forms/ContactInfo'
import { Button } from '@arckit/ui'
import { Col, Row } from 'components/grid'
import { states } from '../../constants'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import catchError from 'utils/catchError'
import { Label, Select, TextArea, TextInput, ValidationError } from '../global/FormInputs'
import ReCAPTCHA from 'react-google-recaptcha'
import addLeadToSalesforceCampaign from 'components/forms/addLeadToSalesforceCampaign'
import { supabase } from 'clients/supabase'
import { format } from 'date-fns'
import { P } from 'components/typography/Paragraph'

interface Props {
	title?: string
	text?: string
	navigateTo?: string
	bottomContact?: boolean
}

const CustomerCareForm = ({
	title = 'Need help with something?',
	text = 'Fill out the form below, and a representative from our team will be in contact you shortly!',
	navigateTo = '/thank-you',
	bottomContact = true
}: Props) => {
	const topics = ['Questions/Concerns About an Order', 'Shipment/Delivery Information', 'Content/Editorial Feedback', 'Invoices and Receipts', 'Other']

	const initalState = {
		topic: '',
		purchaseOrder: '',
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		city: '',
		state: '',
		zipCode: '',
		district: '',
		position: '',
		comments: ''
	}

	const [{ topic, purchaseOrder, firstName, lastName, email, phone, city, state, zipCode, district, position, comments }, setState] = useState(initalState)
	const [showPurchaseOrder, setShowPurchaseOrder] = useState(false)
	const [sending, setSending] = useState(false)
	const [notRobot, setNotRobot] = useState(false)
	const { register, errors, handleSubmit, clearErrors, unregister } = useForm({
		mode: 'onBlur'
	})

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target
		setState((prevState) => ({ ...prevState, [name]: value }))
	}

	useEffect(() => {
		if (topic === 'Questions/Concerns About an Order' || topic === 'Shipment/Delivery Information' || topic === 'Invoices and Receipts') setShowPurchaseOrder(true)
		else setShowPurchaseOrder(false)
	}, [topic])

	useEffect(() => {
		clearErrors('purchaseOrder')
		unregister('purchaseOrder')
	}, [topic, clearErrors, unregister])

	const submitForm = async () => {
		try {
			setSending(true)

			await supabase.from('form_submissions').insert({
				topic: topic,
				purchase_order: purchaseOrder,
				first_name: firstName,
				last_name: lastName,
				email: email,
				phone: phone,
				title: position,
				city: city,
				state: state,
				zip_code: zipCode,
				school_district: district,
				comments: comments,
				page: 'Contact Us'
			})

			axios.post(`/api/email/customer-care`, {
				contact: {
					topic,
					purchaseOrder,
					firstName,
					lastName,
					email,
					phone,
					city,
					state,
					zipCode,
					district,
					position,
					comments,
					pageName: 'Contact Us',
					submitDate: format(new Date(), 'MMM do yyyy, h:mm:ss a')
				}
			})

			axios.post(`/api/salesforce/capture-lead`, {
				contact: {
					firstName,
					lastName,
					email,
					phone,
					position,
					city,
					state,
					zipCode,
					district,
					comments,
					pageName: 'Contact Us',
					submitDate: format(new Date(), 'MMM do yyyy, h:mm:ss a')
				}
			})

			setSending(false)
			navigate(navigateTo, {
				state: {
					first_name: firstName,
					zip_code: zipCode,
					params: new URLSearchParams({ topic, purchaseOrder, firstName, lastName, email, phone, city, state, zipCode, district, position, comments }).toString(),
					showPricingGuide: false,
					showSummerPricingGuide: false
				}
			})
		} catch (error) {
			setSending(false)
			catchError(error)
		}
	}

	return (
		<div className="mb-6 rounded border bg-slate-50 p-8 shadow-lg">
			<span className="mb-2 block font-inter text-3xl font-extrabold">{title}</span>
			<P className="mb-6">{text}</P>
			<form noValidate name="Customer Care Page" onSubmit={handleSubmit(submitForm)}>
				<Row>
					<Col width="w-full mb-6" gutter="sm" className="mb-6">
						<Label name="Topic" htmlFor="topic" />
						<Select
							id="topic"
							name="topic"
							value={topic}
							onChange={handleChange}
							validation={register({
								required: 'Please select a topic'
							})}
						>
							<option value=""></option>
							{topics.map((topic) => {
								return (
									<option key={topic} value={topic}>
										{topic}
									</option>
								)
							})}
						</Select>
						<ValidationError>{errors.topic && errors.topic.message}</ValidationError>
					</Col>
				</Row>
				<Row className={`${showPurchaseOrder ? 'flex' : 'hidden'}`}>
					<Col width="w-full" gutter="sm" className="mb-6">
						<Label name="Purchase Order #" htmlFor="purchaseOrder" required />
						<TextInput
							id="purchaseOrder"
							name="purchaseOrder"
							value={purchaseOrder}
							onChange={handleChange}
							type="text"
							required={showPurchaseOrder}
							validation={
								showPurchaseOrder
									? register({
											required: 'Purchase order is required',
											minLength: {
												value: 2,
												message: 'Purchase order must be at least 5 characters'
											}
									  })
									: undefined
							}
						/>
						<ValidationError>{errors.purchaseOrder && errors.purchaseOrder.message}</ValidationError>
					</Col>
				</Row>
				<Row>
					<Col width="w-full sm:w-1/2" gutter="sm" className="mb-6">
						<Label name="First Name" htmlFor="firstName" required />
						<TextInput
							id="firstName"
							name="firstName"
							value={firstName}
							onChange={handleChange}
							type="text"
							required
							validation={register({
								required: 'First name is required',
								minLength: {
									value: 2,
									message: 'First name must be at least 2 characters'
								}
							})}
						/>
						<ValidationError>{errors.firstName && errors.firstName.message}</ValidationError>
					</Col>
					<Col width="w-full sm:w-1/2" gutter="sm" className="mb-6">
						<Label name="Last Name" htmlFor="lastName" required />
						<TextInput
							id="lastName"
							name="lastName"
							value={lastName}
							onChange={handleChange}
							type="text"
							required
							validation={register({
								required: 'Last name is required',
								minLength: {
									value: 2,
									message: 'Last name must be at least 2 characters'
								}
							})}
						/>
						<ValidationError>{errors.lastName && errors.lastName.message}</ValidationError>
					</Col>
				</Row>
				<Row>
					<Col width="w-full sm:w-1/2" gutter="sm" className="mb-6">
						<Label name="Email" htmlFor="email" required />
						<TextInput
							id="email"
							name="email"
							value={email}
							onChange={handleChange}
							type="email"
							required
							validation={register({
								required: 'Email is required',
								pattern: {
									// eslint-disable-next-line
									value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Please enter a valid email address'
								}
							})}
						/>
						<ValidationError>{errors.email && errors.email.message}</ValidationError>
					</Col>
					<Col width="w-full sm:w-1/2" gutter="sm" className="mb-6">
						<Label name="Phone" htmlFor="phone" />
						<TextInput id="phone" name="phone" value={phone} onChange={handleChange} type="text" />
					</Col>
				</Row>
				<Row>
					<Col width="w-full sm:w-1/3" gutter="sm" className="mb-6">
						<Label name="City" htmlFor="city" />
						<TextInput id="city" name="city" value={city} onChange={handleChange} type="text" />
					</Col>
					<Col width="w-full sm:w-1/3" gutter="sm" className="mb-6">
						<Label name="State" htmlFor="state" />
						<Select id="state" name="state" value={state} onChange={handleChange}>
							<option value=""></option>
							{states.map((state) => {
								return (
									<option key={state} value={state}>
										{state}
									</option>
								)
							})}
						</Select>
					</Col>
					<Col width="w-full sm:w-1/3" gutter="sm" className="mb-6">
						<Label name="Zip Code" htmlFor="zipCode" required />
						<TextInput
							id="zipCode"
							name="zipCode"
							value={zipCode}
							onChange={handleChange}
							type="text"
							required
							validation={register({
								required: 'Zip code is required',
								pattern: {
									value: /^\d{5}$/,
									message: 'Zip code must be 5 numbers'
								}
							})}
						/>
						<ValidationError>{errors.zipCode && errors.zipCode.message}</ValidationError>
					</Col>
					<Col width="w-full sm:w-1/2" gutter="sm" className="mb-6">
						<Label name="Position" htmlFor="position" />
						<TextInput id="position" name="position" value={position} onChange={handleChange} type="text" />
					</Col>
					<Col width="w-full sm:w-1/2" gutter="sm" className="mb-6">
						<Label name="District" htmlFor="district" />
						<TextInput id="district" name="district" value={district} onChange={handleChange} type="text" />
					</Col>
				</Row>
				<Row>
					<Col gutter="sm" className="mb-6">
						<Label name="Comments" htmlFor="comments" />
						<TextArea id="comments" name="comments" rows="4" value={comments} onChange={handleChange} />
					</Col>
				</Row>
				<Row>
					<Col gutter="sm" className="mb-6 mt-2">
						<div className="mb-6">
							<ReCAPTCHA sitekey="6LdF0c4cAAAAAII8vYhSmG_RW6hpzB8PTeeDtSz-" onChange={() => setNotRobot(true)} />
						</div>
						<Button loading={sending} type="submit" size="lg" disabled={sending || !notRobot}>
							Get Support
						</Button>
					</Col>
				</Row>
			</form>
			{bottomContact && <ContactInfo />}
		</div>
	)
}

export default CustomerCareForm
