import { Accordion, AccordionItem } from 'components/accordion'
import { Col, Row } from 'components/grid'
import { P } from 'components/typography/Paragraph'
import Title from 'components/typography/Title'
import React from 'react'

const FaqAccordion = () => {
	return (
		<Row>
			<Col>
				<Accordion>
					<AccordionItem title="How do I contact Customer Care?" top>
						<P shade="dark">
							Call us at <a href="tel:8668102665">(866) 810-2665</a>
						</P>
						<P shade="dark">
							Email us at <a href="mailto:customercare@americanreading.com">customercare@americanreading.com</a>
						</P>
						<P shade="dark">
							Fill out the <a href="#contact-form">contact form</a> below.
						</P>
					</AccordionItem>
					<AccordionItem title="What are my options for payment?">
						<Title className="mb-2">Purchase Orders</Title>
						<P shade="dark">
							Purchase Orders can be sent to <a href="mailto:orders@americanreading.com">orders@americanreading.com</a>
						</P>
						<P shade="dark" className="mb-3">
							Purchase Orders should contain the following information:
						</P>
						<ul className="list-disc pl-6 text-lg text-slate-400">
							<li className="mb-2 text-base text-slate-700 sm:text-lg">A valid purchase order number</li>
							<li className="mb-2 text-base text-slate-700 sm:text-lg">Authorized signature</li>
							<li className="mb-2 text-base text-slate-700 sm:text-lg">Shipping address and contact information (including email address)</li>
							<li className="mb-2 text-base text-slate-700 sm:text-lg">Billing address and contact information (including email address)</li>
							<li className="mb-2 text-base text-slate-700 sm:text-lg">Any upcoming school closure dates within the next 30 days</li>
						</ul>
						<Title className="mb-2 mt-8">Credit Card Payment</Title>
						<P shade="dark">
							Credit card orders must contain valid billing information and contact details. We accept MasterCard, Visa, American Express, and Discover. There is a 3%
							PayPal fee for orders over $500.
						</P>
						<Title className="mb-2 mt-8">Check Payment</Title>
						<P shade="dark">All checks must be made payable to American Reading Company.</P>
						<Title className="mb-2 mt-8">Payment Terms</Title>
						<ul className="mb-6 list-disc pl-6 text-lg text-slate-400">
							<li className="mb-2 text-base text-slate-700 sm:text-lg">Payment terms are Net 30.</li>
							<li className="mb-2 text-base text-slate-700 sm:text-lg">Please see above for payment options.</li>
							<li className="mb-2 text-base text-slate-700 sm:text-lg">Don’t forget to add any required sales or local taxes.</li>
						</ul>
					</AccordionItem>
					<AccordionItem title="How does Professional Learning work?">
						<P shade="dark" className="mb-3">
							American Reading Company limits all workshops to a maximum of 25 participants (20 teachers and 5 support staff). If more than 25 people attend the
							workshop, you agree to pay $350 for each additional participant. Professional development training cannot be cancelled or changed within 15 days of the
							event date. Customers that cancel professional development events within 15 days will be billed for the event.
						</P>
						<P shade="dark" className="mb-3">
							American Reading Company retains proprietary rights to its professional development, both in person and virtual; therefore, recording of professional
							development is prohibited. ARC reserves the right to maintain distribution of its professional development services.
						</P>
						<P shade="dark">
							American Reading Company reserves the right to convert in person professional development, upon consultation with the customer, to a combination of
							virtual professional development, physical materials, and/or digital materials when necessary to ensure the health and safety of its employees and
							customers.
						</P>
					</AccordionItem>
					<AccordionItem title="How do exchanges work?">
						<P className="text-slate-700">
							Refunds are not issued. Exchanges are allowed for order discrepancies and/or damaged products and must be reported within 90 days of receipt of order to
							receive replacement product. Marked, stickered, stamped, or otherwise altered materials are not eligible for exchange. Beyond the ninety days allowed
							for replacement of items deemed defective, ARC disclaims all warranties, expressed or implied.
						</P>
						<P shade="dark" className="mb-3">
							Please contact us prior to returning items to American Reading Company to ensure your exchange is processed correctly and you receive the appropriate
							replacement. American Reading Company will pay for return shipping if the exchange is a result of company error.
						</P>
						<P shade="dark" className="mb-3">
							If you have questions regarding your order, please contact our Customer Care Department at customercare@americanreading.com or (866) 810-2665.
						</P>
						<P shade="dark" className="mb-3">
							Please have your order number available when requesting an exchange. This will expedite our ability to assist you. Your order number is a 5 or 6 digit
							number preceded by an 'h' (i.e., h123456). Please note that your order number may follow a different format if you purchased other partnership materials
							that ARC offers. Your order number can be found:
						</P>
						<ul className="mb-6 list-disc pl-6 text-xl text-slate-400">
							<li className="mb-2 text-base text-slate-700 sm:text-lg">On the front page or bottom right of your proposal OR on your web quote.</li>
							<li className="mb-2 text-base text-slate-700 sm:text-lg">On the shipment labels on your boxes.</li>
							<li className="mb-2 text-base text-slate-700 sm:text-lg">At the top of your packing slip.</li>
						</ul>
					</AccordionItem>
				</Accordion>
			</Col>
		</Row>
	)
}

export default FaqAccordion
