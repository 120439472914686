import ContactInfo from 'components/customer-care/ContactInfo'
import FaqAccordion from 'components/customer-care/FaqAccordion'
import CustomerCareForm from 'components/forms/CustomerCareForm'
import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import RepFinder from '../components/contact-us/RepFinder'
import { graphql, useStaticQuery } from 'gatsby'
import { H1 } from 'components/typography/Headings'

export default function ContactUsPage() {
	const data = useStaticQuery<Query>(query).allContentfulAsset.edges[0].node.file.url
	return (
		<Layout pageName="Contact Us">
			<Section>
				<div className="absolute top-0 left-0 h-full w-full bg-cover bg-center bg-no-repeat xl:bg-contain" style={{ backgroundImage: `url('${data}')` }} />
				<Row className="relative py-20 lg:py-40">
					<Col width="w-full md:w-3/4" className="mx-auto text-center">
						<H1 className="text-white" style={{ textShadow: '3px 3px 5px rgba(0, 0, 0, 0.4)' }}>
							Contact Us
						</H1>
					</Col>
				</Row>
			</Section>

			<Section margin="mt-10 md:mt-20">
				<ContactInfo />
			</Section>
			<Section gray className="pt-10">
				<SectionTitle title="We'd Love to Hear From You" />
				<Row className="mb-16">
					<Col>
						<div className="flex justify-center">
							<StaticImage src="../../static/images/state-reviews/us-map.png" alt="us map" className="max-w-xl" />
						</div>
					</Col>
				</Row>
				<RepFinder />
			</Section>

			<Section margin="mt-10 md:mt-20" id="faq">
				<SectionTitle title="Frequently Asked Questions" />
				<FaqAccordion />
			</Section>
			<Section id="contact-form" className="mt-10 md:mt-20">
				<Row className="justify-center">
					<Col width="w-full md:w-5/6 lg:w-3/4 xl:w-1/2">
						<CustomerCareForm />
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

interface Query {
	allContentfulAsset: {
		edges: {
			node: { file: { url: string } }
		}[]
	}
}

const query = graphql`
	query {
		allContentfulAsset(filter: { title: { eq: "Rainbow Collage" } }) {
			edges {
				node {
					file {
						url
					}
				}
			}
		}
	}
`
